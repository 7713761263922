import React from "react";
import { useTranslation } from 'react-i18next';

import Setting from "../setting/setting";
import Ready from "../ready/ready";
import TopBanner from "../top_banner/product_top_banner";
import Approach from "../human_approach/approach";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";

const Telesurveillance = () => {
  const {t} = useTranslation();
  const DataArray = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/signal_in_sqaure_icon_with_bg.svg",
      title: `${t('recording_settings')}`,
      description: `${t('recording_protection_is_ensured_by_encryption')}`,
      listItems: [
        `${t('webcam_recording')}`,
        `${t('audio_recording')}`,
        `${t('screen_recording')}`,
        `${t('saving_the_workspace')}`,
        `${t('registration_of_identity_document')}`,
      ],
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/setting_in_sqaure_icon_with_bg.svg",
      title: `${t('lockdown_browser_settings')}`,
      description: `${t('access_to_external_documents_during_the_exam_session')}`,
      listItems: [
        `${t('full_screen_mode_enabled')}`,
        `${t('other_screen_detected')}`,
        `${t('new_tab_inaccessible')}`,
        `${t('browser_cleaned_upstream')}`,
        `${t('cache_cleaned_downstream')}`,
        `${t('printer_disabled')}`,
        `${t('extension_disabled')}`,
        `${t('right_click_disabled')}`,
        `${t('disabled_keyboard_shortcuts')}`,
      ],
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/eye_in_square_icon_with_bg.svg",
      title: `${t('automated_monitoring')}`,
      description:`${t('when_comparing_mereos_to_other_remote_monitoring')}`,
      listItems: [
        `${t('no_downloads')}`,
        `${t('test_accessible_in_1_click')}`,
        `${t('custom_interface')}`,
        `${t('multi_format_assessments')}`,
        `${t('advanced_grade_analysis')}`,
        "Catalogue APIs",
      ],
    },
  ];
  return (
    <div className={`font-sans`}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <TopBanner
        pageTitle="Télésurveillance"
        title="Une télésurveillance ultrasophistiquée"
        description="La plate-forme Mereos est l’unique solution d’évaluations télésurveillées ne nécessitant aucun téléchargement ni inscription."
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/group-frame.svg"
        page={"platform"}
        leftButton={{
          text: `${t('register')}`,
          href: "https://registration.mereos.eu/candidature/?id=1",
        }}
        rightButton={{
          text: `Le test >`,
          href: "",
        }}
      />

      {/* sub Container */}
      <div className={`mx-auto w-11/12 lg:w-5/6 my-32`}>
        <Setting
          title={t('a_unique_web_app')}
          subTitle={t('with_mereos_the_following_monitoring_settings')}
          Data={DataArray}
        />

        <div className="my-20">
          <Approach
            title="En cas de doute, la session est annulée."
            description1="Mereos se réserve le droit d'annuler un score s'il s'avère qu'une information présentée dans la candidature ou une action réalisée lors du test  soit douteuse, sans avoir à fournir de justification  au candidat."
            imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/masking-before-monitor.svg"
          />
        </div>
      </div>

      {/* <AutomatedLiveComponent/> */}
      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        {/* Ready */}
        <div className="my-32">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: `${t('register')}`,
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
        </div>
        {/* <div className="my-20"> */}
        {/* <Analytics /> */}
      </div>
    </div>
  );
};

export default Telesurveillance;
