import * as React from "react";

import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";
import Telesurveillance from "../../../components/tasem-test/telesurveillance";

const Index = () => {
  return (
    <>
      <Seo
        title="Télésurveillance relative au TASEM"
        description="Le  test  TASEM  se  déroule  en  ligne  sur  la  plate-forme  Mereos,  l’unique  solution  d’évaluations
        télésurveillées ne nécessitant aucun téléchargement. En cas de fraude avérée ou de suspicion de
        fraude, la session est annulée."
      />
      <Layout>
        <Telesurveillance />  
      </Layout>
    </>
  )
}

export default Index;
